var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-md":"","pa-0":""}},[_c('v-layout',{attrs:{"justify-center":"","row":""}},[_c('v-flex',{attrs:{"xs12":"","lg6":"","py-0":""}},[_c('v-combobox',{attrs:{"items":_vm.provinces,"label":"Province","outlined":""},on:{"change":_vm.populateCities},model:{value:(_vm.user.province),callback:function ($$v) {_vm.$set(_vm.user, "province", $$v)},expression:"user.province"}})],1),_c('v-flex',{attrs:{"xs12":"","lg6":"","py-0":""}},[_c('v-select',{attrs:{"items":_vm.cities,"outlined":"","label":"City or Municipality"},model:{value:(_vm.user.city),callback:function ($$v) {_vm.$set(_vm.user, "city", $$v)},expression:"user.city"}})],1),_c('v-flex',{attrs:{"xs12":"","lg4":"","py-0":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({class:{'mt-0' : _vm.$vuetify.breakpoint.mdAndDown},attrs:{"value":"General Practitioner","label":"General Practitioner","disabled":_vm.isGeneral},on:{"change":function($event){_vm.isDisabled = !_vm.isDisabled}},model:{value:(_vm.user.specialization),callback:function ($$v) {_vm.$set(_vm.user, "specialization", $$v)},expression:"user.specialization"}},on))]}}])},[_c('span',[_vm._v("Once ticked, you cannot select other specializations.")])])],1),_c('v-flex',{attrs:{"xs12":"","lg8":"","py-0":""}},[_c('v-select',{attrs:{"outlined":"","multiple":"","chips":"","label":"Specialization","disabled":_vm.isDisabled,"rules":_vm.specsRule,"items":_vm.specialization},on:{"change":_vm.limiter},model:{value:(_vm.user.specialization),callback:function ($$v) {_vm.$set(_vm.user, "specialization", $$v)},expression:"user.specialization"}})],1),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-flex',{attrs:{"xs12":"","pt-0":""}},[_c('v-tooltip',{attrs:{"slot":"prepend","color":"primary","right":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pr-1"},on),[_vm._v("mdi-information")])]}}],null,false,213295229)},[_c('span',[_vm._v("Information you entered shall be kept confidential by our team. This will only be used for verification purposes.")])]),_c('span',{staticClass:"grey--text"},[_vm._v("Professional Information")])],1):_vm._e(),_c('v-flex',{attrs:{"xs12":"","lg3":"","py-0":""}},[_c('v-text-field',{attrs:{"outlined":"","mask":"######","label":"Roll Number","rules":[_vm.rules.required]},model:{value:(_vm.user.rollNumber),callback:function ($$v) {_vm.$set(_vm.user, "rollNumber", $$v)},expression:"user.rollNumber"}},[(!_vm.$vuetify.breakpoint.mdAndDown)?_c('v-tooltip',{attrs:{"slot":"prepend","color":"primary","right":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v("Information you entered shall be kept confidential by our team. ")]),_c('br'),_c('span',[_vm._v("This will only be used for verification purposes.")])]):_vm._e()],1)],1),_c('v-flex',{attrs:{"xs12":"","lg3":"","py-0":""}},[_c('v-text-field',{attrs:{"outlined":"","mask":"####","label":"Year Admitted","rules":[_vm.rules.required]},model:{value:(_vm.user.yearAdmitted),callback:function ($$v) {_vm.$set(_vm.user, "yearAdmitted", $$v)},expression:"user.yearAdmitted"}})],1),_c('v-flex',{attrs:{"xs12":"","lg6":"","py-0":""}},[_c('v-file-input',{attrs:{"label":"Valid government-issued ID","placeholder":"Attach file","rules":_vm.imageSizeRule,"outlined":"","show-size":"","large":"","prepend-icon":"","prepend-inner-icon":"mdi-paperclip"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('v-flex',{attrs:{"xs12":"","py-0":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"rules":_vm.checkBoxRule},model:{value:(_vm.checkBox),callback:function ($$v) {_vm.checkBox=$$v},expression:"checkBox"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" I agree to the "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({staticStyle:{"text-decoration":"none"},attrs:{"slot":"activator","target":"_blank","href":"/terms-and-conditions"},on:{"click":function($event){$event.stopPropagation();}},slot:"activator"},on),[_vm._v("Terms & Conditions.")])]}}])},[_c('span',[_vm._v("Opens in new tab")])])],1)])],1)],1),_c('v-flex',{attrs:{"xs12":"","md8":"","lg4":""}},[_c('v-btn',{attrs:{"loading":this.btnLoading,"color":"primary","large":"","depressed":"","block":""},on:{"click":function($event){return _vm.regFinalStep()}}},[_vm._v("Sign-Up")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }