<template>
  <v-container grid-list-md pa-0>
    <v-layout justify-center row>
      <v-flex xs12 lg6 py-0>
        <v-combobox
          @change="populateCities"
          v-model="user.province"
          :items="provinces"
          label="Province"
          outlined
        ></v-combobox>
      </v-flex>
      <v-flex xs12 lg6 py-0>
        <v-select
          v-model="user.city"
          :items="cities"
          outlined
          label="City or Municipality"
        ></v-select>
      </v-flex>
      <v-flex xs12 lg4 py-0>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-checkbox
              v-model="user.specialization"
              value="General Practitioner"
              label="General Practitioner"
              :disabled="isGeneral"
              @change="isDisabled = !isDisabled"
              :class="{'mt-0' : $vuetify.breakpoint.mdAndDown}"
              v-on="on"
            ></v-checkbox>
          </template>
          <span>Once ticked, you cannot select other specializations.</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs12 lg8 py-0>
        <v-select
          outlined
          multiple
          chips
          label="Specialization"
          :disabled="isDisabled"
          :rules="specsRule"
          :items="specialization"
          @change="limiter"
          v-model="user.specialization"
        ></v-select>
      </v-flex>
      <v-flex v-if="$vuetify.breakpoint.mdAndDown" xs12 pt-0>
        <v-tooltip color="primary" slot="prepend" right >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="pr-1">mdi-information</v-icon>
          </template>
          <span>Information you entered shall be kept confidential by our team. This will only be used for verification purposes.</span>
        </v-tooltip>
        <span class="grey--text">Professional Information</span>
      </v-flex>
      <v-flex xs12 lg3 py-0>
        <v-text-field
          outlined
          mask="######"
          label="Roll Number"
          :rules="[rules.required]"
          v-model="user.rollNumber"
        >
          <v-tooltip v-if="!$vuetify.breakpoint.mdAndDown" color="primary" slot="prepend" right >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-information</v-icon>
            </template>
            <span>Information you entered shall be kept confidential by our team. </span><br />
            <span>This will only be used for verification purposes.</span>
          </v-tooltip>
        </v-text-field>
      </v-flex>
      <v-flex xs12 lg3 py-0>
        <v-text-field
          outlined
          mask="####"
          label="Year Admitted"
          :rules="[rules.required]"
          v-model="user.yearAdmitted"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 lg6 py-0>
        <v-file-input 
          v-model="file"
          label="Valid government-issued ID" 
          placeholder="Attach file"
          :rules="imageSizeRule"
          outlined 
          show-size
          large
          prepend-icon=""
          prepend-inner-icon="mdi-paperclip"
        >
        </v-file-input>
      </v-flex>
      <v-flex xs12 py-0>
        <v-row justify="center">
          <v-checkbox class="mt-0" :rules="checkBoxRule" v-model="checkBox">
            <span slot="label">
              I agree to the
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <a style="text-decoration: none;" slot="activator" target="_blank" href="/terms-and-conditions" @click.stop v-on="on">Terms & Conditions.</a>
                </template>
                <span>Opens in new tab</span>
              </v-tooltip>
            </span>
          </v-checkbox>
        </v-row>
      </v-flex>
      <v-flex xs12 md8 lg4>
        <v-btn
          :loading='this.btnLoading'
          @click="regFinalStep()"
          color="primary"
          large
          depressed
          block
        >Sign-Up</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import VueCookie from 'vue-cookie'
import Philippines from 'philippines'
import rulesMixin from '@/mixins/rulesMixin'
import specializationMixin from '@/mixins/specializationMixin'

export default {
  name: 'FinalStepLawyerCard',
  mixins: [
    rulesMixin,
    specializationMixin
  ],
  data () {
    return {
      btnLoading: false,
      isDisabled: false,
      isGeneral: false,
      provinces: [],
      cities: [],
      file: [],
      provinceKey: '',
      checkBox: '',
      user: {},
      specsRule: [(v) => v.length >= 1 || 'Plesase enter at least one specialization.'],
      imageSizeRule: [ value => !value || value.size < 2000000 || 'Image size should be less than 2 MB.' ],
      checkBoxRule: [(v) => v === true || 'You must agree to our Terms & Conditions']
    }
  },
  methods: {
    limiter (e) {
      if (e.length > 0) {
        this.isGeneral = true
      }
      else if (e.length === 0) {
        this.isGeneral = false
      }
      if (e.length > 3) {
        this.$swal({
          type: 'error',
          text: 'You can only select three specializations.'
        })
        e.pop()
      }
    },
    populateProvinces () {
      for (let i = 0; i < Philippines.provinces.length; i++) {
        this.provinces.push(Philippines.provinces[i].name)
      }
      this.provinces.sort()
    },
    populateCities () {
      this.cities = []
      let selectedProvinceKey
      for (let i = 0; i < Philippines.provinces.length; i++) {
        if (Philippines.provinces[i].name === this.user.province) {
          selectedProvinceKey = Philippines.provinces[i].key
        }
      }
      for (let x = 0; x < Philippines.cities.length; x++) {
        if (Philippines.cities[x].province === selectedProvinceKey) {
          this.cities.push(Philippines.cities[x].name)
        }
      }
    },
    regFinalStep () {
      this.btnLoading = true
      let formData = new FormData()
      formData.append('userId', VueCookie.get('userId'))
      formData.append('province', this.user.province)
      formData.append('city', this.user.city)
      formData.append('yearAdmitted', this.user.yearAdmitted)
      formData.append('rollNumber', this.user.rollNumber)
      formData.append('specialization', this.user.specialization)
      formData.append('ibpCard', this.file)
      this.$store.dispatch('auth/setOAuthUserLawyer', { formData })
        .then(res => {
          this.$router.push('/jobs')
        })
        .finally(() => {
          this.btnLoading = false
        })
    }
  },
  mounted () {
    this.populateProvinces()
  }
}
</script>

<style>

</style>