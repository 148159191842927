export default {
  data () {
    return {
      legalAdviceDescription: [
        (v) => v.length >= 30 || 'Plesase enter at least 30 characters.',
        (v) => v.length <= 3000 || 'Please enter at most 3000 characters.'
      ],
      rules: {
        required: value => !!value || `This field is required.`,
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
      }
    }
  }
}
