<template>
  <v-layout v-if="!isFetching" justify-center align-center py-9>
    <v-flex xs12 md10 lg6 xl5>
      <div class="text-center">
        <img src="https://res.cloudinary.com/starupgeek-law/image/upload/v1583571530/assets/txrdl3nqu81mbs7ykxyk.png" :width="!$vuetify.breakpoint.mdAndDown ? '40%' : '50%'">
      </div>
      <p class="text-center px-3 pt-9 font-weight-medium">The <span class="primary px-2 py-1 white--text rounded">{{ user.fullName }}</span> account at {{ user.googleId ? 'Google' : 'Facebook' }} isn't connected to LawyerKo.</p>
      <p class="text-center px-3 font-weight-medium">
        Connect this account by signing in to LawyerKo as a Client or Lawyer.<br />
        Then you’ll be able to sign in with {{ user.googleId ? 'Google' : 'Facebook' }} going forward.
      </p>
      <v-card class="pa-6">
        <p class="text-center title font-weight-medium">Register as</p>
        <div class="home_header_buttons text-center pb-12">
          <v-btn @click="registerOption(1), isLawyer = true" depressed :color="btnLeftColor" class="btn_home" large>Lawyer</v-btn>
          <v-btn @click="registerOption(0), isLawyer = false" depressed :color="btnRightColor" class="btn_home" large>Client</v-btn>
        </div>
        <LawyerCard v-if="isLawyer" />
        <ClientCard v-else />
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import LawyerCard from '@/components/RegisterComponents/FinalStepLawyerCard'
import UserService from '@/services/UserService'
import ClientCard from '@/components/RegisterComponents/FinalStepClientCard'

export default {
  name: 'FinalStep',
  components: {
    LawyerCard,
    ClientCard
  },
  data () {
    return {
      isFetching: false,
      btnLeftColor: '',
      btnRightColor: '',
      isLawyer: '',
      fullName: ''
    }
  },
  methods: {
    registerOption (index) {
      if (index === 0) {
        this.$store.commit('control/SET_REGISTER_TAB_INDEX', index)
        this.btnRightColor = 'primary'
        this.btnLeftColor = 'grey lighten-4'
      } else {
        this.$store.commit('control/SET_REGISTER_TAB_INDEX', index)
        this.btnRightColor = 'grey lighten-4'
        this.btnLeftColor = 'primary'
      }
    },
    setGoogleReg () {
      this.isFetching = true
      this.$store.dispatch('auth/setInitialGoogleReg', { code: this.$route.query.code, scope: this.$route.query.scope, authuser: this.$route.query.authuser })
        .then(res => {
          if(res.province) {
            if (res.isLawyer) {
              this.$router.push('/jobs')
            } else {
              this.$router.push('/post-problem')
            }
          }
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    setFacebookReg () {
      this.isFetching = true
      this.$store.dispatch('auth/setInitialFacebook', { code: this.$route.query.code })
        .then(res => {
          if (res.province) {
            if (res.isLawyer) {
              this.$router.push('/jobs')
            } else {
              this.$router.push('/post-problem')
            }
          }
        })
        .finally(() => {
          this.isFetching = false
        })
    }
  },
  mounted () {
    if (this.$store.getters['control/registerTabIndex'] === 0) {
      this.btnRightColor = 'primary'
      this.btnLeftColor = 'grey lighten-4'
    } else {
      this.btnLeftColor = 'primary'
      this.btnRightColor = 'grey lighten-4'
    }
  },
  created () {
    if (this.$route.query.authuser) {
      this.setGoogleReg()
    } else {
      this.setFacebookReg()
    }
  },
  computed: {
    user () {
      return this.$store.getters['user/user'].user
    }
  }
}
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .25s
  }
  .fade-enter, .fade-leave-to {
    opacity: 0
  }
  .home_header_buttons {
    margin-left: -16px;
  }
  .home_header_buttons .btn_home {
    position: relative;
    text-transform: uppercase;
    font-size: 15px;
    margin-right: -3px;
    line-height: 13px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .home_header_buttons .btn_home:first-child {
    border-right: none;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
  .home_header_buttons .btn_home:first-child::after {
    position: absolute;
    display: block;
    right: 0;
    z-index: 1;
  }
  .home_header_buttons .btn_home:last-child {
    border-left: none;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    padding-right: 50px;
  }
  .viewport-height {
    height: 860px;
  }
</style>
