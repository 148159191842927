<template>
  <v-container grid-list-md pa-0>
    <v-layout justify-center row>
      <v-flex xs12 lg6 py-0>
        <v-combobox
          @change="populateCities"
          v-model="user.province"
          :items="provinces"
          label="Province"
          outlined
        ></v-combobox>
      </v-flex>
      <v-flex xs12 lg6 py-0>
        <v-select
          v-model="user.city"
          :items="cities"
          outlined
          label="City or Municipality"
        ></v-select>
      </v-flex>
      <v-flex xs12 md9 py-0>
        <v-row justify="center">
          <v-checkbox class="mt-0" :rules="checkBoxRule" v-model="checkBox">
            <span slot="label">
              I agree to the
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <a style="text-decoration: none;" slot="activator" target="_blank" href="/terms-and-conditions" @click.stop v-on="on">Terms & Conditions.</a>
                </template>
                <span>Opens in new tab</span>
              </v-tooltip>
            </span>
          </v-checkbox>
        </v-row>
      </v-flex>
      <v-flex xs12 md8 lg4>
        <v-btn
          :loading='this.btnLoading'
          @click="regFinal()"
          color="primary"
          large
          depressed
          block
        >Sign-Up</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import VueCookie from 'vue-cookie'
import Philippines from 'philippines'
import rulesMixin from '@/mixins/rulesMixin'

export default {
  name: 'FinalStepClientCard',
  data () {
    return {
      btnLoading: false,
      provinces: [],
      cities: [],
      user: {},
      checkBox: '',
      checkBoxRule: [(v) => v === true || 'You must agree to our Terms & Conditions']
    }
  },
  methods: {
    populateProvinces () {
      for (let i = 0; i < Philippines.provinces.length; i++) {
        this.provinces.push(Philippines.provinces[i].name)
      }
      this.provinces.sort()
    },
    populateCities () {
      this.cities = []
      let selectedProvinceKey
      for (let i = 0; i < Philippines.provinces.length; i++) {
        if (Philippines.provinces[i].name === this.user.province) {
          selectedProvinceKey = Philippines.provinces[i].key
        }
      }
      for (let x = 0; x < Philippines.cities.length; x++) {
        if (Philippines.cities[x].province === selectedProvinceKey) {
          this.cities.push(Philippines.cities[x].name)
        }
      }
    },
    regFinal () {
      this.btnLoading = true
      this.$store.dispatch('auth/setOAuthUserClient', { userId: VueCookie.get('userId'), city: this.user.city, province: this.user.province })
        .then(res => {
          this.$router.push('/post-problem')
        })
    }
  },
  mounted () {
    this.populateProvinces()
  }
}
</script>

<style>

</style>